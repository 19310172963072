<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-card class="pb-16 pa-10">
      <div class="text-h2 primary--text font-weight-bold text-center mb-4">
        {{ $t("complaints.complaintsCanadaTitle") }}
      </div>

      <p>
        {{ $t("complaints.introduction") }}
      </p>
      <p>
        {{ $t("complaints.step1.title") }}
      </p>
      <p>
        {{ $t("complaints.step1.description") }}
      </p>
      <ul>
        <li>
          {{ $t("complaints.step1.phoneText") }}: <a :href="'tel:'+$t('complaints.step1.phone')">{{ $t('complaints.step1.phone') }}</a>
        </li>
        <li>
          {{ $t("complaints.step1.emailText") }}: <a href="mailto:customerservice@blanket.com"> {{ $t("complaints.step1.email") }}</a>
        </li>
      </ul>
      <p>
        {{ $t("complaints.step2.title") }}
      </p>
      <p>{{ $t("complaints.step2.description") }}</p>
      <p>
        {{ $t("complaints.step3.title") }}
      </p>
      <p>
        {{ $t("complaints.step3.description") }}
      </p>
      <p>
        {{ $t("complaints.step3.lifeAndHealthInsurance.title") }}
      </p>
      <ul>
        <li>
          <a
            href="http://olhi.ca/"
            target="_blank"
          >{{ $t("complaints.step3.lifeAndHealthInsurance.options.one") }}</a>
        </li>
        <li>
          <a
            href="https://lautorite.qc.ca/en/general-public"
            target="_blank"
          >{{ $t("complaints.step3.lifeAndHealthInsurance.options.two") }}</a>
        </li>
      </ul>
      <p>
        {{ $t("complaints.step3.otherInsurances.title") }}
      </p>
      <ul>
        <li>
          <a
            href="http://www.giocanada.org/"
            target="_blank"
          >
            {{ $t("complaints.step3.otherInsurances.options.one") }}

          </a>
        </li>
        <li>
          <a
            href="https://lautorite.qc.ca/en/general-public"
            target="_blank"
          >            {{ $t("complaints.step3.otherInsurances.options.two") }}
          </a>
        </li>
      </ul>
      <p>
        {{ $t("complaints.step4.title") }}
      </p>
      <p>
        {{ $t("complaints.step4.description") }}
      </p>
    </v-card>
  </v-container>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped></style>
